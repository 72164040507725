import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Grid,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000'
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  image: {
    //perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
        maxWidth: '30%',
        height: 'auto',
        //transform: 'rotateY(-35deg) rotateX(15deg)',
        backfaceVisibility: 'hidden',
        //boxShadow: theme.shadows[0]
    }
},
}));

const TopBar = ({ className, ...rest }) => {
  
  const classes = useStyles();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.image}>
          <img
            alt="LouveRoof"
            src="/static/louveRoofLogo.png"
          />
        </div>
        <Box flexGrow={1} />
        <Grid container spacing={3} direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm>
            <Typography variant="body2" component={RouterLink} style={{color: 'white'}} onClick={() => openInNewTab('https://www.louveroof.com/our-louvered-pergola')}>The Design</Typography>
          </Grid>
          <Grid item sm>
          <Typography variant="body2" component={RouterLink} style={{color: 'white'}} onClick={() => openInNewTab('https://www.louveroof.com/residential')}>Gallery</Typography>
          </Grid>
          <Grid item sm>
          <Typography variant="body2" component={RouterLink} style={{color: 'white'}} onClick={() => openInNewTab('https://www.louveroof.com/louvered-roof-research')}>Research</Typography>
          </Grid>
          <Grid item sm>
          <Typography variant="body2" component={RouterLink} style={{color: 'white'}} onClick={() => openInNewTab('https://www.louveroof.com/contact-us')}>Contact Us</Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

//Documentation link
//<Link
//  className={classes.link}
//  color="textSecondary"
//  component={RouterLink}
//  to="/docs"
//  underline="none"
//  variant="body2"
//>
//  Documentation
//        </Link>