import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
  
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    layout: MainLayout,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/',
    component: lazy(() => import('src/views/mainFolder'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/noblesville_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/noblesville_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/noblesville_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/noblesville_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/noblesville_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/anchorage_ak_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anchorage_ak_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anchorage_ak_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anchorage_ak_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anchorage_ak_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/juneau_ak_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/juneau_ak_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/juneau_ak_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/juneau_ak_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/juneau_ak_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/birmingham_al_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/birmingham_al_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/birmingham_al_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/birmingham_al_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/birmingham_al_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/montogmery_al_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/montogmery_al_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/montogmery_al_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/montogmery_al_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/montogmery_al_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bentonville_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bentonville_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bentonville_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bentonville_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bentonville_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/springdale_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springdale_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springdale_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springdale_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springdale_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/jonesboro_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jonesboro_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jonesboro_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jonesboro_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jonesboro_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/conway_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/conway_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/conway_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/conway_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/conway_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/rogers_ar_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rogers_ar_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rogers_ar_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rogers_ar_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rogers_ar_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/scottsdale_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scottsdale_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scottsdale_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scottsdale_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scottsdale_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gilbert_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gilbert_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gilbert_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gilbert_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gilbert_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tempe_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tempe_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tempe_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tempe_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tempe_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/surprise_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/surprise_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/surprise_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/surprise_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/surprise_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/yuma_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yuma_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yuma_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yuma_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yuma_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/flagstaff_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flagstaff_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flagstaff_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flagstaff_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flagstaff_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/phoenix_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/phoenix_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/phoenix_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/phoenix_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/phoenix_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/tucson_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tucson_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tucson_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tucson_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tucson_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/mesa_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesa_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesa_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesa_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesa_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/chandler_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chandler_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chandler_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chandler_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chandler_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/glendale_az_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/glendale_az_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/glendale_az_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/glendale_az_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/glendale_az_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fresno_ca_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fresno_ca_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fresno_ca_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fresno_ca_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fresno_ca_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/sacramento_ca_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sacramento_ca_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sacramento_ca_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sacramento_ca_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sacramento_ca_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/oakland_ca_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oakland_ca_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oakland_ca_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oakland_ca_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oakland_ca_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bakersfield_ca_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bakersfield_ca_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bakersfield_ca_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bakersfield_ca_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bakersfield_ca_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/anaheim_ca_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaheim_ca_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaheim_ca_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaheim_ca_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaheim_ca_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/denver_co_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/denver_co_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/denver_co_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/denver_co_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/denver_co_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/waterbury_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterbury_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterbury_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterbury_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterbury_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/norwalk_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norwalk_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norwalk_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norwalk_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norwalk_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/danbury_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/meriden_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meriden_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meriden_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meriden_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meriden_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bristol_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bristol_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bristol_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bristol_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bristol_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bridgeport_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bridgeport_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bridgeport_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bridgeport_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bridgeport_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hartford_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hartford_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hartford_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hartford_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hartford_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/stamford_ct_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stamford_ct_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stamford_ct_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stamford_ct_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stamford_ct_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dover_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/newark_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/middletown_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/middletown_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/middletown_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/middletown_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/middletown_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/smyrna_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milford_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seaford_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/elsmere_de_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elsmere_de_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elsmere_de_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elsmere_de_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elsmere_de_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/pensacola_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pensacola_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pensacola_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pensacola_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pensacola_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/naples_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naples_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naples_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naples_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naples_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/orlando_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orlando_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orlando_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orlando_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orlando_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/jacksonville_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jacksonville_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jacksonville_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jacksonville_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jacksonville_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hialeah_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hialeah_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hialeah_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hialeah_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hialeah_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tallahassee_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tallahassee_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tallahassee_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tallahassee_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tallahassee_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/miami_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/miami_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/miami_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/miami_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/miami_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/destin_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/destin_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/destin_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/destin_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/destin_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tampa_fl_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cumming_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cumming_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cumming_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cumming_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cumming_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rome_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rome_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rome_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rome_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rome_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gainesville_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/marietta_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/marietta_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/marietta_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/marietta_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/marietta_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/kennesaw_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kennesaw_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kennesaw_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kennesaw_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kennesaw_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/acworth_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/acworth_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/acworth_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/acworth_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/acworth_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/mcdonough_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mcdonough_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mcdonough_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mcdonough_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mcdonough_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/atlanta_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/atlanta_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/atlanta_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/atlanta_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/atlanta_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/savannah_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/savannah_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/savannah_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/savannah_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/savannah_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dunwoody_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dunwoody_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dunwoody_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dunwoody_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dunwoody_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/athens_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/athens_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/athens_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/athens_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/athens_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/norcross_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norcross_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norcross_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norcross_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norcross_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/macon_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/macon_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/macon_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/macon_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/macon_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/milton_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milton_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milton_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milton_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milton_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/valdosta_ga_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/valdosta_ga_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/valdosta_ga_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/valdosta_ga_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/valdosta_ga_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/waterloo_ia_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterloo_ia_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterloo_ia_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterloo_ia_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waterloo_ia_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/ames_ia_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ames_ia_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ames_ia_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ames_ia_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ames_ia_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dubuque_ia_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dubuque_ia_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dubuque_ia_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dubuque_ia_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dubuque_ia_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/ankeny_ia_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ankeny_ia_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ankeny_ia_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ankeny_ia_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ankeny_ia_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/davenport_ia_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/davenport_ia_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/davenport_ia_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/davenport_ia_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/davenport_ia_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/nampa_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nampa_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nampa_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nampa_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nampa_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/pocatello_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pocatello_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pocatello_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pocatello_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pocatello_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/caldwell_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/caldwell_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/caldwell_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/caldwell_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/caldwell_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/boise_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boise_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boise_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boise_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boise_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_id_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_id_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_id_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_id_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_id_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/peoria_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/elgin_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elgin_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elgin_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elgin_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elgin_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/waukegan_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waukegan_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waukegan_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waukegan_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/waukegan_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cicero_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cicero_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cicero_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cicero_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cicero_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/chicago_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chicago_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chicago_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chicago_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chicago_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/aurora_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/aurora_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/aurora_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/aurora_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/aurora_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rockford_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockford_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockford_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockford_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockford_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/joliet_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/joliet_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/joliet_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/joliet_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/joliet_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/naperville_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naperville_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naperville_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naperville_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/naperville_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_il_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_il_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_il_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_il_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_il_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/evansville_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evansville_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evansville_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evansville_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evansville_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hammond_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hammond_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hammond_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hammond_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hammond_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gary_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gary_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gary_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gary_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gary_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/carmel_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carmel_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carmel_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carmel_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carmel_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fishers_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fishers_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fishers_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fishers_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fishers_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/indianapolis_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/indianapolis_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/indianapolis_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/indianapolis_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/indianapolis_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/muncie_in_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/muncie_in_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/muncie_in_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/muncie_in_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/muncie_in_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lenexa_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lenexa_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lenexa_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lenexa_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lenexa_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/salina_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salina_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salina_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salina_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salina_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/topeka_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/topeka_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/topeka_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/topeka_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/topeka_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/olathe_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/olathe_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/olathe_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/olathe_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/olathe_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lawrence_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawrence_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawrence_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawrence_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawrence_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/wichita_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wichita_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wichita_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wichita_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wichita_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/shawnee_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shawnee_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shawnee_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shawnee_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shawnee_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/manhattan_ks_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manhattan_ks_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manhattan_ks_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manhattan_ks_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manhattan_ks_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/florence_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/georgetown_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/owensboro_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/owensboro_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/owensboro_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/owensboro_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/owensboro_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/covington_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/covington_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/covington_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/covington_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/covington_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hopkinsville_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hopkinsville_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hopkinsville_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hopkinsville_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hopkinsville_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_ky_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_ky_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_ky_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_ky_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_ky_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/shreveport_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shreveport_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shreveport_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shreveport_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/shreveport_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lafayette_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/kenner_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenner_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenner_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenner_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenner_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/monroe_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/monroe_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/monroe_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/monroe_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/monroe_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/houma_la_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houma_la_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houma_la_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houma_la_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houma_la_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/boston_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boston_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boston_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boston_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/boston_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/brockton_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/brockton_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/brockton_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/brockton_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/brockton_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/worcester_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/worcester_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/worcester_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/worcester_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/worcester_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/quincy_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/quincy_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/quincy_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/quincy_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/quincy_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lynn_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lynn_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lynn_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lynn_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lynn_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lowell_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lowell_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lowell_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lowell_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lowell_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cambridge_ma_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cambridge_ma_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cambridge_ma_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cambridge_ma_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cambridge_ma_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/laurel_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laurel_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laurel_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laurel_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laurel_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/baltimore_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/baltimore_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/baltimore_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/baltimore_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/baltimore_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/frederick_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/frederick_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/frederick_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/frederick_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/frederick_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rockville_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockville_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockville_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockville_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rockville_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gaithersburg_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gaithersburg_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gaithersburg_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gaithersburg_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gaithersburg_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bowie_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bowie_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bowie_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bowie_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bowie_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hagerstown_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hagerstown_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hagerstown_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hagerstown_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hagerstown_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/annapolis_md_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/annapolis_md_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/annapolis_md_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/annapolis_md_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/annapolis_md_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/portland_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lewiston_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bangor_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bangor_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bangor_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bangor_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bangor_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/auburn_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/auburn_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/auburn_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/auburn_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/auburn_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/biddeford_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biddeford_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biddeford_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biddeford_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biddeford_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/augusta_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/saco_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/saco_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/saco_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/saco_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/saco_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/westbrook_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westbrook_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westbrook_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westbrook_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westbrook_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/scarborough_me_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scarborough_me_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scarborough_me_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scarborough_me_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scarborough_me_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lansing_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lansing_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lansing_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lansing_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lansing_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/flint_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flint_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flint_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flint_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/flint_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dearborn_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dearborn_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dearborn_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dearborn_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dearborn_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/livonia_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/livonia_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/livonia_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/livonia_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/livonia_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/detroit_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/detroit_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/detroit_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/detroit_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/detroit_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/westland_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westland_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westland_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westland_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/westland_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/warren_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warren_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warren_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warren_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warren_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/kalamazoo_mi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalamazoo_mi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalamazoo_mi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalamazoo_mi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalamazoo_mi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bloomington_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/plymouth_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plymouth_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plymouth_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plymouth_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plymouth_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/eagan_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eagan_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eagan_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eagan_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eagan_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/woodbury_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woodbury_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woodbury_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woodbury_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woodbury_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/minneapolis_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/minneapolis_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/minneapolis_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/minneapolis_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/minneapolis_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/duluth_mn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/duluth_mn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/duluth_mn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/duluth_mn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/duluth_mn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_mo_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_mo_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_mo_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_mo_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_mo_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/independence_mo_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/independence_mo_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/independence_mo_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/independence_mo_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/independence_mo_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_mo_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_mo_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_mo_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_mo_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_mo_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gulfport_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gulfport_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gulfport_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gulfport_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gulfport_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/southaven_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/southaven_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/southaven_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/southaven_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/southaven_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hattiesburg_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hattiesburg_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hattiesburg_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hattiesburg_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hattiesburg_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/biloxi_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biloxi_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biloxi_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biloxi_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/biloxi_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/meridian_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tupelo_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tupelo_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tupelo_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tupelo_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tupelo_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_ms_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_ms_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_ms_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_ms_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_ms_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/anaconda_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaconda_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaconda_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaconda_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/anaconda_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/billings_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/billings_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/billings_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/billings_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/billings_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/missoula_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/missoula_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/missoula_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/missoula_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/missoula_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/bozeman_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bozeman_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bozeman_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bozeman_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bozeman_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/butte_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/butte_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/butte_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/butte_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/butte_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/helena_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/helena_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/helena_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/helena_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/helena_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/kalispell_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalispell_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalispell_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalispell_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kalispell_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/havre_mt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/havre_mt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/havre_mt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/havre_mt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/havre_mt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/durham_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/durham_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/durham_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/durham_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/durham_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fayetteville_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cary_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cary_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cary_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cary_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cary_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wilmington_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/charlotte_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charlotte_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charlotte_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charlotte_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charlotte_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/raleigh_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/raleigh_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/raleigh_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/raleigh_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/raleigh_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/greensboro_nc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greensboro_nc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greensboro_nc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greensboro_nc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greensboro_nc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fargo_nd_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fargo_nd_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fargo_nd_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fargo_nd_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fargo_nd_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bismarck_nd_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bismarck_nd_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bismarck_nd_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bismarck_nd_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bismarck_nd_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lincoln_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lincoln_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lincoln_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lincoln_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lincoln_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/omaha_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/omaha_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/omaha_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/omaha_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/omaha_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/kearney_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kearney_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kearney_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kearney_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kearney_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/fremont_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fremont_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fremont_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fremont_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fremont_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hastings_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hastings_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hastings_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hastings_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hastings_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ne_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ne_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ne_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ne_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_ne_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/manchester_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manchester_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manchester_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manchester_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/manchester_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/nashua_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashua_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashua_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashua_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashua_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/concord_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/concord_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/concord_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/concord_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/concord_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dover_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dover_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/keene_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/keene_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/keene_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/keene_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/keene_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/laconia_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laconia_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laconia_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laconia_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laconia_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/claremont_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/claremont_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/claremont_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/claremont_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/claremont_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lebanon_nh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lebanon_nh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lebanon_nh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lebanon_nh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lebanon_nh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/paterson_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/paterson_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/paterson_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/paterson_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/paterson_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/elizabeth_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elizabeth_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elizabeth_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elizabeth_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elizabeth_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/trenton_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/trenton_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/trenton_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/trenton_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/trenton_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/clifton_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clifton_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clifton_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clifton_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clifton_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/camden_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/camden_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/camden_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/camden_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/camden_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/passaic_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/passaic_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/passaic_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/passaic_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/passaic_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/union_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/union_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/union_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/union_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/union_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/newark_nj_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_nj_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_nj_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_nj_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newark_nj_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gallup_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gallup_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gallup_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gallup_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gallup_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/deming_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/deming_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/deming_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/deming_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/deming_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/albuquerque_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albuquerque_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albuquerque_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albuquerque_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albuquerque_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roswell_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/farmington_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/farmington_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/farmington_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/farmington_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/farmington_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/alamogordo_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alamogordo_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alamogordo_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alamogordo_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alamogordo_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/carlsbard_nm_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carlsbard_nm_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carlsbard_nm_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carlsbard_nm_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/carlsbard_nm_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fernley_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fernley_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fernley_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fernley_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fernley_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/elko_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elko_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elko_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elko_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/elko_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/mesquite_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesquite_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesquite_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesquite_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/mesquite_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/henderson_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/reno_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reno_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reno_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reno_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reno_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/sparks_nv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sparks_nv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sparks_nv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sparks_nv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sparks_nv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/buffalo_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/buffalo_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/buffalo_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/buffalo_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/buffalo_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rochester_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/yonkers_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yonkers_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yonkers_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yonkers_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yonkers_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/syracuse_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/syracuse_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/syracuse_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/syracuse_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/syracuse_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/albany_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/schenectady_ny_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/schenectady_ny_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/schenectady_ny_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/schenectady_ny_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/schenectady_ny_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/canton_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/canton_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/canton_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/canton_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/canton_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/youngstown_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/youngstown_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/youngstown_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/youngstown_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/youngstown_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lorain_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lorain_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lorain_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lorain_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lorain_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbus_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cleveland_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cleveland_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cleveland_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cleveland_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cleveland_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cincinnati_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cincinnati_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cincinnati_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cincinnati_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cincinnati_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/toledo_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/toledo_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/toledo_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/toledo_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/toledo_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/akron_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/akron_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/akron_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/akron_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/akron_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dayton_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dayton_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dayton_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dayton_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dayton_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/parma_oh_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parma_oh_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parma_oh_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parma_oh_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parma_oh_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/tulsa_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tulsa_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tulsa_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tulsa_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tulsa_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/norman_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norman_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norman_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norman_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norman_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lawton_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawton_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawton_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawton_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lawton_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/edmond_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/edmond_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/edmond_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/edmond_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/edmond_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/moore_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/moore_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/moore_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/moore_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/moore_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/enid_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/enid_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/enid_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/enid_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/enid_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/stillwater_ok_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stillwater_ok_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stillwater_ok_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stillwater_ok_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/stillwater_ok_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gresham_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gresham_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gresham_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gresham_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gresham_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/hillsboro_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hillsboro_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hillsboro_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hillsboro_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/hillsboro_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/beaverton_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beaverton_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beaverton_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beaverton_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beaverton_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bend_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bend_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bend_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bend_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bend_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/medford_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/medford_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/medford_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/medford_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/medford_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/springfield_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/corvallis_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/corvallis_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/corvallis_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/corvallis_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/corvallis_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/portland_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portland_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/eugene_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eugene_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eugene_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eugene_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/eugene_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/salem_or_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salem_or_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salem_or_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salem_or_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/salem_or_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/allentown_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/allentown_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/allentown_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/allentown_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/allentown_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/erie_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/erie_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/erie_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/erie_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/erie_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/reading_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reading_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reading_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reading_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/reading_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/scranton_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scranton_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scranton_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scranton_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/scranton_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/philadelphia_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/philadelphia_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/philadelphia_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/philadelphia_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/philadelphia_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bethlehem_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bethlehem_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bethlehem_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bethlehem_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bethlehem_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/lancaster_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lancaster_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lancaster_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lancaster_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/lancaster_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/harrisburg_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/harrisburg_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/harrisburg_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/harrisburg_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/harrisburg_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/altoona_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/altoona_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/altoona_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/altoona_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/altoona_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/pittsburgh_pa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pittsburgh_pa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pittsburgh_pa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pittsburgh_pa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pittsburgh_pa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/providence_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/providence_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/providence_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/providence_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/providence_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/warwick_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warwick_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warwick_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warwick_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/warwick_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cranston_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cranston_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cranston_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cranston_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cranston_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/pawtucket_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pawtucket_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pawtucket_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pawtucket_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/pawtucket_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/woonsocket_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woonsocket_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woonsocket_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woonsocket_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/woonsocket_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/newport_ri_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newport_ri_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newport_ri_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newport_ri_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/newport_ri_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/florence_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/florence_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/columbia_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/greenville_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/summerville_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/summerville_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/summerville_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/summerville_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/summerville_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/sumter_sc_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sumter_sc_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sumter_sc_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sumter_sc_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sumter_sc_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/siouxfalls_sd_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/siouxfalls_sd_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/siouxfalls_sd_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/siouxfalls_sd_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/siouxfalls_sd_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/nashville_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashville_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashville_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashville_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/nashville_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/knoxville_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/knoxville_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/knoxville_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/knoxville_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/knoxville_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/chattanooga_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chattanooga_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chattanooga_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chattanooga_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chattanooga_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/clarksville_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksville_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksville_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksville_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksville_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/murfreesboro_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/murfreesboro_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/murfreesboro_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/murfreesboro_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/murfreesboro_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/memphis_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/memphis_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/memphis_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/memphis_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/memphis_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/franklin_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/franklin_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/franklin_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/franklin_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/franklin_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bartlett_tn_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bartlett_tn_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bartlett_tn_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bartlett_tn_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bartlett_tn_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/laredo_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laredo_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laredo_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laredo_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laredo_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/houston_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houston_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houston_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houston_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/houston_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/dallas_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dallas_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dallas_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dallas_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/dallas_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/austin_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/austin_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/austin_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/austin_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/austin_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/arlington_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/arlington_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/arlington_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/arlington_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/arlington_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/plano_tx_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plano_tx_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plano_tx_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plano_tx_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/plano_tx_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/provo_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/provo_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/provo_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/provo_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/provo_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/orem_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orem_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orem_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orem_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/orem_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/sandy_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sandy_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sandy_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sandy_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sandy_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/ogden_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ogden_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ogden_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ogden_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/ogden_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/layton_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/layton_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/layton_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/layton_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/layton_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/taylorsville_ut_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/taylorsville_ut_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/taylorsville_ut_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/taylorsville_ut_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/taylorsville_ut_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/roanoke_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roanoke_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roanoke_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roanoke_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/roanoke_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/portsmouth_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/suffolk_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/suffolk_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/suffolk_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/suffolk_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/suffolk_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/norfolk_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/chesapeake_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chesapeake_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chesapeake_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chesapeake_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/chesapeake_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/richmond_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_va_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_va_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_va_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_va_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/alexandria_va_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/burlington_vt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/burlington_vt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/burlington_vt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/burlington_vt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/burlington_vt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/rutland_vt_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rutland_vt_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rutland_vt_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rutland_vt_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/rutland_vt_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/renton_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/renton_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/renton_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/renton_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/renton_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/spokane_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/spokane_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/spokane_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/spokane_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/spokane_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/tacoma_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tacoma_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tacoma_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tacoma_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/tacoma_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/vancouver_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/vancouver_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/vancouver_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/vancouver_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/vancouver_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/bellevue_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/everett_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/everett_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/everett_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/everett_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/everett_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/seattle_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seattle_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seattle_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seattle_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/seattle_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

  {
    exact: true,
    layout: MainLayout,
    path: '/kent_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kent_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kent_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kent_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kent_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/yakima_wa_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yakima_wa_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yakima_wa_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yakima_wa_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/yakima_wa_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/milwaukee_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milwaukee_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milwaukee_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milwaukee_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/milwaukee_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/racine_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/racine_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/racine_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/racine_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/racine_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/appleton_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/appleton_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/appleton_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/appleton_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/appleton_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/madison_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/madison_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/madison_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/madison_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/madison_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/oshkosh_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oshkosh_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oshkosh_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oshkosh_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/oshkosh_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/kenosha_wi_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenosha_wi_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenosha_wi_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenosha_wi_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/kenosha_wi_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/clarksburg_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksburg_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksburg_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksburg_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/clarksburg_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/parkersburg_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parkersburg_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parkersburg_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parkersburg_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/parkersburg_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/morgantown_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/morgantown_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/morgantown_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/morgantown_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/morgantown_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/wheeling_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wheeling_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wheeling_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wheeling_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/wheeling_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/beckley_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beckley_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beckley_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beckley_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/beckley_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },



  {
    exact: true,
    layout: MainLayout,
    path: '/weirton_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/weirton_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/weirton_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/weirton_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/weirton_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/fairmont_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fairmont_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fairmont_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fairmont_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/fairmont_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/charleston_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/martinsburg_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/martinsburg_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/martinsburg_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/martinsburg_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/martinsburg_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/huntington_wv_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/huntington_wv_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/huntington_wv_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/huntington_wv_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/huntington_wv_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/laramie_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laramie_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laramie_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laramie_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/laramie_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/cheyenne_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cheyenne_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cheyenne_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cheyenne_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/cheyenne_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/gillette_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gillette_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gillette_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gillette_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/gillette_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/sheridan_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sheridan_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sheridan_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sheridan_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/sheridan_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/evanston_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evanston_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evanston_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evanston_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/evanston_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/casper_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/casper_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/casper_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/casper_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/casper_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/riverton_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/riverton_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/riverton_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/riverton_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/riverton_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },


  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_wy_pergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_wy_cover',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_wy_awning',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_wy_louveredroof',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/jackson_wy_louveredPergola',
    component: lazy(() => import('src/views/louveRoofLocations'))
  },

];

export default routes;
